import axios from 'axios'
import { environment } from '@/data/environment'
import qs from 'qs';
export default {
  state: {
    loggedInUser:
      localStorage.getItem('uid') != null
        ? JSON.parse(atob(localStorage.getItem('uid')))
        : null,
    loading: false,
    error: null,
    user: null,
    nameUser: null,
  },
  getters: {
    loggedInUser: state => state.loggedInUser,
    loading: state => state.loading,
    error: state => state.error,
  },
  mutations: {
    setUser (state, data) {
      state.loggedInUser = data
      state.loading = false
      state.error = null
      state.user = data.user
      state.nameUser = data.name
    },
    setLoading (state, data) {
      state.loading = data
      state.error = null
    },
    setError (state, data) {
      state.error = data
      state.loggedInUser = null
      state.loading = false
    },
    clearError (state) {
      state.error = null
    },
  },
  actions: {
    login ({ commit }, data) {
      const email = data.email
      const endpoint = '/loginUi'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      axios
        .create()
        .post(url,data)
        .then(response => {
         const dataResponse = response.data
          if (dataResponse.Error) {
            commit('setError', dataResponse.Mensaje)
          } else {
            const dataUser = { user: email, nameUser: dataResponse.data.name, idcompania: dataResponse.data.compania, rol: dataResponse.data.rol }
            localStorage.setItem('uid', btoa(JSON.stringify(dataUser)))
            commit('setUser', dataUser)
          }
        })
        .catch(function (error) {
          console.log(error.message)
          localStorage.removeItem('uid')
        })
    },
    clearerror ({ commit }) {
      commit('clearError')

    },
    logout ({ commit }) {
      localStorage.removeItem('uid')
    },
  },
}
