import axios from 'axios'
import { environment } from '@/data/environment/'

export default {
  namespaced: true,
  state: {
    error: null,
    listInvoice: [],
    listInvoiceDetail: [],
    fileBase64: '',
    fileName: '',
    responseReprocess: [],
    responseEmail: null,
  },
  getters: {},
  mutations: {

    setListInvoice (state, data) {
      state.listInvoice = data
      state.error = null
    },
    setListInvoiceDetail (state, data) {
      state.listInvoiceDetail = data
      state.error = null
    },
    setFileBase64 (state, data) {
      state.fileBase64 = data
      state.error = null
    },
    setNameFileBase64 (state, data) {
      state.fileName = data
      state.error = null
    },
    setResponseReprocess (state, data) {
      state.responseReprocess = data
      state.error = null
    },
    setResponseEmail (state, data) {
      state.responseEmail = data
      state.error = null
    },

  },
  actions: {
    getListInvoice ({ commit }, data) {
      let params = 'IdCompania=' + JSON.parse(atob(localStorage.getItem('uid'))).idcompania

      if (!data) {
        let dateCurrent = new Date()
        dateCurrent = dateCurrent.getFullYear() + '-' + String(dateCurrent.getMonth() + 1).padStart(2, '0') + '-' + String(dateCurrent.getDate()).padStart(2, '0')
        params += `&FechaInicial= ${dateCurrent}`
        params += `&FechaFinal= ${dateCurrent}`
      } else {
        if (data.Consecutivo) {
          params += `&ConsecutivoDocumento= ${data.Consecutivo}`
        }
        if (data.Status && data.Status !== '') {
          params += `&Estado= ${data.Status}`
        }
        if (data.Prefix) {
          params += `&Prefijo= ${data.Prefix}`
        }
        if (data.dates) {
          params += `&FechaInicial= ${data.dates[0]}`
          params += `&FechaFinal= ${data.dates[1]}`
        }
      }
      const endpoint = '/getInvoices?'
      const url = environment.HIOPOSWEBSERVICES + endpoint + params
      axios
        .create()
        .get(url)
        .then(response => {
          if (!response.data.respuesta.Error) {
            commit('setListInvoice', response.data.respuesta.Datos)
            return
          }

          console.log('Error /getInvoices', response.data)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    getListInvoiceDetail ({ commit }, data) {
      let params = 'IdCompania=' + JSON.parse(atob(localStorage.getItem('uid'))).idcompania
      params += `&Consecutivo=${data.Consecutivo}`
      params += `&Prefijo=${data.Prefijo}`

      const endpoint = '/getInvoicesDetail?'
      const url = environment.HIOPOSWEBSERVICES + endpoint + params
      axios
        .create()
        .get(url)
        .then(response => {
          if (!response.data.respuesta.Error) {
            commit('setListInvoiceDetail', response.data.respuesta.Datos)
          }
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    getListInvoiceDetail ({ commit }, data) {
      let params = 'IdCompania=' + JSON.parse(atob(localStorage.getItem('uid'))).idcompania
      params += `&Consecutivo=${data.Consecutivo}`
      params += `&Prefijo=${data.Prefijo}`

      const endpoint = '/getInvoicesDetail?'
      const url = environment.HIOPOSWEBSERVICES + endpoint + params
      axios
        .create()
        .get(url)
        .then(response => {
          if (!response.data.respuesta.Error) {
            commit('setListInvoiceDetail', response.data.respuesta.Datos)
          }
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },

    reprocessInvoice ({ commit }, data) {
      let params = ''
      params += `?consecutivoDocumento= ${data.Prefijo}-${data.Consecutivo} + `
      params += `&idCompania= ${data.IdCompania}`

      const endpoint = '/sincronizacionFE?'
      const url = environment.HIOPOSWEBSERVICES + endpoint + params
      axios
        .create()
        .get(url)
        .then(response => {
          commit('setResponseReprocess', response.data.respuesta)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },

    getDataFile ({ commit }, data) {
      const ruta = data.url.substr(26)
      const params = `ruta=${ruta}`

      const endpoint = '/getFile?'
      const url = environment.HIOPOSWEBSERVICES + endpoint + params
      commit('setNameFileBase64', data.nameFile)

      axios
        .create()
        .get(url)
        .then(response => {
          commit('setFileBase64', response.data.respuesta)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },

    sendEmail ({ commit }, data) {
      let params = ''
      params += `?consecutivoDocumento= ${data.Prefijo}-${data.Consecutivo} + `
      params += `&forzar=1&idCompania= ${data.IdCompania}`

      const endpoint = '/notificacionFE'
      const url = environment.HIOPOSWEBSERVICES + endpoint + params

      axios
        .create()
        .get(url)
        .then(response => {
            commit('setResponseEmail', response.data.respuesta)
        })
        .catch(function (error) {
          commit('setResponseEmail', null)
          console.log(error.message)
        })
    },

    setResponseEmail ({ commit }, data) {
      commit('setResponseEmail', data)
    },

  },
}
