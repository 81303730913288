import axios from 'axios'
import { environment } from '@/data/environment/'

export default {
  namespaced: true,
  state: {
    statusResponse: false,
    updateStatusResponse: false,
    listClients: [],
  },
  mutations: {

    setListClients (state, data) {
      state.listClients = data
    },
    setResponseClient (state, data) {
      state.statusResponse = data
    },
    setUpdateResponseClient (state, data) {
      state.updateStatusResponse = data
    },
  },
  actions: {
    getListClients ({ commit }) {
      const endpoint = '/getClients'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      axios
        .create()
        .get(url)
        .then(response => {
          commit('setListClients', response.data)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    insertClients ({ commit }, data) {
      const endpoint = '/add-compania'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      axios
        .create()
        .post(url, data)
        .then(response => {
          if (!response.data.respuesta.Error) {
              commit('setResponseClient', true);
          }else{
            console.log(response.data);
          }
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    activeInactiveClient ({ commit }, data) {
      const endpoint = '/updateStatusClient'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      axios
        .create()
        .post(url, data)
        .then(response => {
          if (response.data.Mensaje) {
            commit('setUpdateResponseClient', response.data.Mensaje)
          }
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    setStatusResponse ({ commit }, data) {
      commit('setResponseClient', data)
    },
    setUpdateResponseClient ({ commit }, data) {
      commit('setUpdateResponseClient', data)
    },
  },
}
