import axios from 'axios'
import { environment } from '@/data/environment/'

export default {
  namespaced: true,
  state: {
    error: null,
    listStatisticsInvoice: [],
  },
  getters: {},
  mutations: {

    setStatistics (state, data) {
      state.listStatisticsInvoice = data
      state.error = null
    },

  },
  actions: {
    getStatistics ({ commit }) {
      let params = 'IdCompania=' + JSON.parse(atob(localStorage.getItem('uid'))).idcompania
      let dateCurrent = new Date()
      dateCurrent = dateCurrent.getFullYear() + '-' + String(dateCurrent.getMonth() + 1).padStart(2, '0') + '-' + String(dateCurrent.getDate()).padStart(2, '0')
      // params += `&Fecha= ${dateCurrent}`
      params += `&Fecha=2022-08-16`

      const endpoint = '/getStatistics?'
      const url = environment.HIOPOSWEBSERVICES + endpoint + params
      axios
        .create()
        .get(url)
        .then(response => {
          if (!response.data.respuesta.Error) {
            commit('setStatistics', response.data.respuesta.Datos[0])
          }
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },

  },
}
