import axios from 'axios'
import { environment } from '@/data/environment/'

export default {
  namespaced: true,
  state: {
    statusResponseFirma: false,
    listFirmas: [],

  },
  mutations: {

    setListFirmas (state, data) {
      state.listFirmas = data
    },
    setResponseFirma (state, data) {
      state.statusResponseFirma = data
    },

  },
  actions: {
    getListFirmas ({ commit }) {
      const params = '?IdCompania=' + JSON.parse(atob(localStorage.getItem('uid'))).idcompania

      const endpoint = '/getFirmas'
      const url = environment.HIOPOSWEBSERVICES + endpoint + params
      axios
        .create()
        .get(url)
        .then(response => {
          if (!response.data.respuesta.Error) {
            commit('setListFirmas', response.data.respuesta.Datos[0])
            return true
          }
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    insertFirma ({ commit }, data) {
      const formData = new FormData()

      formData.append('IdCompania', JSON.parse(atob(localStorage.getItem('uid'))).idcompania)
      formData.append('Firma', data.Firma)
      formData.append('Clave', data.Clave)
      formData.append('VigenciaDesde', data.VigenciaDesde)
      formData.append('VigenciaHasta', data.VigenciaHasta)

      const endpoint = '/add-firma'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      const headers = {
        'Content-Type': 'multipart/form-data',
      }

      axios
        .create(headers)
        .post(url, formData)
        .then(response => {
          if (!response.data.respuesta.Error) {
            commit('setResponseFirma', true)
          }
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    setStatusResponse ({ commit }, data) {
      commit('setResponseFirma', data)
    },
  },
}
